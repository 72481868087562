<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M7 19V13H17V19H19V7.828L16.172 5H5V19H7ZM4 3H17L21 7V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9 15V19H15V15H9Z"
    />
  </svg>
</template>

<script>
export default {
  name: "SaveIcon",
};
</script>
